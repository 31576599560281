import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Doctors from './pages/Doctors';
import Centers from './pages/Centers';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Appointments from './pages/Appointments';
import Patiants from './pages/Patiants';
import Services from './pages/Services';
import Wallet from './pages/Wallet';
import Articles from './pages/Articles';
import Inbox from './pages/Inbox';
import Packages from './pages/Packages';
import Profile from './pages/Profile';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'profile', element: <Profile /> },
        { path: 'doctors', element: <Doctors /> },
        { path: 'centers', element: <Centers /> },
        { path: 'patiants', element: <Patiants /> },
        { path: 'services', element: <Services /> },
        { path: 'appointments', element: <Appointments /> },
        { path: 'wallet', element: <Wallet /> },
        { path: 'articles', element: <Articles /> },
        { path: 'packages', element: <Packages /> },
        { path: 'inbox', element: <Inbox /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
